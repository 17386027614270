import React, { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";

function CursoAsedes() {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return(
    <React.Fragment>
      <Helmet>
          <title>Curso de lengua de señas de Guatemala en ASEDES | LENSEGUA</title>
          <meta name="description" content="Contamos con cursos en modalidad virtual y presencial con diferentes opciones de niveles y horarios, todos nuestros instructores son personas Sordas." />
      </Helmet>
  <div class="main-content" id="inicioNot">
    <div class="content">
      <div class="w-container">
        <h1 class="heading bold">Curso lengua de señas de Guatemala en ASEDES</h1>
        <div class="written-by"><img src="../images/logo-asedes-lensegua.png" loading="lazy" alt="" class="image-institution" />
          <div class="information-institution">
            <a href="http://www.asedesguatemala.org" class="information custom-link w-inline-block">
              <div class="link-to-institution">www.asedesguatemala.org</div>
            </a>
            <div>
              <div class="information">ASEDES - Asociación Educativa para el Sordo</div>
              <div class="div-block-5"><img src="../images/whatsapp-icono-lensegua.svg" loading="lazy" alt="" class="image-10" />
                <div class="information">+502 4189-0285</div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-information">
          <h2 class="blog-section-title">Quienes somos</h2>
          <div class="blog-paragraphs">Somos una organización guatemalteca, civil, no lucrativa, que agrupa a personas <strong>sordas</strong> y <strong>oyentes</strong> interesadas en trabajar en beneficio de personas con discapacidad auditiva. Comprometidos a realizar todo tipo de actividades para la atención integral del sordo mejorando su calidad de vida y la de su familia.</div>
          <div class="blog-paragraphs">ASEDES fue fundado legalmente en el año 2008 con mucho amor y dedicación hacia los niños y la juventud sorda de Guatemala por Niurka Waleska Bendfeldt Rosada con el apoyo de padres de familia y personas sordas, el proyecto se fundó con el objetivo de desarrollar proyectos educativos y de soporte para el beneficio de la comunidad Sorda en Guatemala.</div><img src="../images/462543895_2196101554119762_7469993357430509530_n.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, (max-width: 991px) 728px, 940px" srcset="../images/462543895_2196101554119762_7469993357430509530_n-p-500.jpg 500w, ../images/462543895_2196101554119762_7469993357430509530_n-p-800.jpg 800w, ../images/462543895_2196101554119762_7469993357430509530_n-p-1080.jpg 1080w, ../images/462543895_2196101554119762_7469993357430509530_n.jpg 1600w" alt="Miembros de ASEDES Guatemala - LENSEGUA" />
          <h2 class="blog-section-title">Qué hacemos</h2>
          <div class="blog-paragraphs">Nos enfocamos en tres ejes de trabajo:</div>
          <div class="blog-informacion-items">
            <div class="blog-paragraphs small bold">1.<br/></div>
            <div class="blog-paragraphs">Enseñanza y divulgación de Lengua de Señas de Guatemala - LENSEGUA con más de 16 años de experiencia buscamos educar a población en general haciendo uso de estrategias de enseñanza-aprendizaje a fin de optimizar el proceso de adquisición de LENSEGUA como segunda lengua además de sensibilizar y dar a conocer la cultura de las personas sordas.<br /></div>
          </div>
          <div class="blog-informacion-items">
            <div class="blog-paragraphs small bold">2.<br/></div>
            <div class="blog-paragraphs">Atención Terapéutica a niños y jóvenes con discapacidad auditiva y todo lo relacionado a educación para personas Sordas. Somos fieles creyentes que el camino a una vida digna se basa en la educación de calidad. </div>
          </div>
          <div class="blog-informacion-items">
            <div class="blog-paragraphs small bold">3.<br/></div>
            <div class="blog-paragraphs">Incidencia política y lucha por la igualdad de derechos y oportunidades de las personas sordas. En este eje buscamos alzar la voz para dar a conocer nuestras necesidades a la vez que mediante dialogo buscamos que se den mejoras que nos dirijan hacia la equidad de oportunidades y derechos.</div>
          </div>
        </div>
        <h2 class="blog-section-title">Quieres aprender Lengua de señas de Guatemala</h2>
        <div class="blog-paragraphs">Contamos con cursos en modalidad <strong>virtual y presencial</strong> con diferentes opciones de niveles y horarios, todos nuestros instructores son personas Sordas y nuestros cursos están pensados para llevar a una persona sin conocimiento previo de la lengua de señas hasta un nivel de dominio avanzado, así como dar el impulso y ser el puente que une a las personas oyentes con las personas sordas.</div>
        <div class="blog-paragraphs">Tenemos siempre 4 convocatorias anuales en los meses 🗓️ de enero, abril, julio y septiembre para más información puedes buscar a ASEDES en redes sociales donde se publican detalles y demás temas de interés sobre la cultura sorda en Guatemala.</div>
        <div class="w-layout-grid grid-5">
          <img src="../images/462557642_440893685781524_4049982032749416000_n.jpg" loading="lazy" id="w-node-_97423acc-4c95-2dfa-9436-5be6eb214c64-b4571ae6" sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, (max-width: 991px) 728px, 940px" alt="Alumnos de ASEDES en curso de lenguaje señas de Guatemala" srcset="../images/462557642_440893685781524_4049982032749416000_n-p-500.jpg 500w, ../images/462557642_440893685781524_4049982032749416000_n-p-800.jpg 800w, ../images/462557642_440893685781524_4049982032749416000_n.jpg 1599w" />
          <img src="../images/462547890_479517058477922_6339403836099964981_n.jpg" loading="lazy" id="w-node-_339ca06f-d745-c830-819e-1718d05d2817-b4571ae6" sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, (max-width: 991px) 728px, 940px" alt="Alumnos de ASEDES culminando curso de lenguaje señas de Guatemala" srcset="../images/462547890_479517058477922_6339403836099964981_n-p-500.jpg 500w, ../images/462547890_479517058477922_6339403836099964981_n-p-800.jpg 800w, ../images/462547890_479517058477922_6339403836099964981_n-p-1080.jpg 1080w, ../images/462547890_479517058477922_6339403836099964981_n.jpg 1600w" />
        </div>
        <h2 class="blog-section-title">Datos para contactar ASEDES</h2>
        <div>
          <a href="http://www.asedesguatemala.org" class="link-block-4 w-inline-block">
            <div class="blog-paragraphs">www.asedesguatemala.org</div>
          </a>
          <div class="div-block-6"><img src="../images/whatsapp-icono-lensegua.svg" loading="lazy" alt="" class="iconos-datos" />
            <div class="blog-paragraphs datos">+502 4189-0285</div>
          </div>
          <div class="div-block-6"><img src="../images/telefono-icono-lensegua.svg" loading="lazy" alt="" class="iconos-datos" />
            <div class="blog-paragraphs datos">23312123</div>
          </div>
          <div class="div-block-6"><img src="../images/ubicacion-icono-lensegua.png" loading="lazy" alt="" class="iconos-datos" />
            <div class="blog-paragraphs datos">7º Avenida 14-44 zona 9 Edificio La Galeria, Local 1</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <a href="https://kitsord.com/" target="_blank" class="w-inline-block w-clearfix">
      <div class="powered_txt">Powered by</div><img src="../images/kitsord_logo_imago.svg" alt="" class="image-5" />
    </a>
  </div>
    </React.Fragment>
  );
}

export default CursoAsedes;
import React from 'react';
import { Link } from 'react-router-dom';

function Root() {
  return (
    <React.Fragment>
  <div id="inicio" class="cover">
    <div class="container w-container"><img src="images/hello2.gif" alt="Aprende lengua de señas" class="image-2"/>
      <h1 class="heading">¿Qué es <strong class="bold-text-2">LENSEGUA?</strong></h1>
      <div class="text-block-2">Lengua de señas de Guatemala. Es la lengua oficial para poder comunicarse en lengua de señas en Guatemala (según Decreto 3-2020) Gracias a los esfuerzos de varias asociaciones lograron que se formalizara este tema.</div>
      <Link to={"/reto-lensegua"} class="reto-link w-inline-block">
        <div class="reto-boton">
          <div class="reto-boton-texto">¿Quiéres hacer un reto LENSEGUA? 👀</div>
        </div>
      </Link>
    </div>
    <a href="#donde_anchor" class="link-block-2 w-inline-block"><img src="images/ic_down.svg" alt="" class="image-6"/></a>
  </div>
  <div class="whereto_row mod_row">
    <div class="container-3 w-container">
      <h2 class="section_titles">¿Qué señas te gustaría aprender de LENSEGUA?<br/></h2>
      <div class="text-block-2 custom">Dale click al botón <strong>Ir a formulario </strong>para ingresar las palabras que te gustaría aprender acerca de LENSEGUA.</div>
    </div>
    <div class="div-block">
      <Link to={"/sigue-aprendiendo-lengua-senas"} class="button reverse-colors w-button">Ir a formulario</Link>
    </div>
  </div>
  <div class="whereto_row mod_row">
    <div class="container-3 w-container">
      <h2 class="section_titles">¿Por qué aprender LENSEGUA?<br/></h2>
      <div class="text-block-2 custom">En una sociedad diversa, el respeto y la <strong>inclusión</strong> de todas las personas, independientemente de sus capacidades, son fundamentales para construir un entorno más equitativo. En este contexto, aprender LENSEGUA (Lengua de Señas de Guatemala) se convierte en una herramienta clave para promover la inclusión de la comunidad sorda en los distintos ámbitos de la vida social, cultural y profesional.</div>
      <div class="text-block-2 custom">LENSEGUA no es solo un medio de comunicación, sino una puerta hacia el entendimiento y la empatía. Al aprender esta lengua, no solo adquirimos una nueva habilidad, sino que también derribamos barreras de comunicación que históricamente han aislado a las personas sordas. Con cada persona que aprende LENSEGUA, estamos creando espacios más accesibles y justos, donde la interacción entre oyentes y sordos se realiza de manera más natural y respetuosa.</div>
      <div class="text-block-2 custom">Incluir a las personas sordas a través de la comunicación en su lengua materna es un paso hacia la verdadera equidad. Este aprendizaje nos permite ofrecerles una participación activa en la educación, el trabajo, la cultura y otros sectores de la vida cotidiana, lo que no solo beneficia a la comunidad sorda, sino que enriquece a la sociedad en su conjunto.</div>
    </div>
  </div>
  <div id="noticias" class="whereto_row">
    <div class="container-2 w-container">
      <h2 id="donde_anchor" class="section_titles">Noticias LENSEGUA</h2>
      <div class="text-block-2 custom">Entérate de las noticias acerca de LENSEGUA, donde podrás conocer más sobre la cultura sorda, conocer más de las asociaciones o instituciones que imparten cursos de lengua de señas, próximos cursos de LENSEGUA, etc.</div>
      <div class="w-layout-grid grid-6">
        <a href="/curso-lengua-de-senas-asedes" class="link-noticia w-inline-block">
          <div id="w-node-d918159b-988a-abf8-5754-ab2e4c092cae-ae89f6c1" class="contenedor-noticia"><img src="images/462557642_440893685781524_4049982032749416000_n.jpg" loading="lazy" sizes="(max-width: 767px) 92vw, (max-width: 991px) 728px, 940px" srcset="images/462557642_440893685781524_4049982032749416000_n-p-500.jpg 500w, images/462557642_440893685781524_4049982032749416000_n-p-800.jpg 800w, images/462557642_440893685781524_4049982032749416000_n.jpg 1599w" alt="Alumnos de ASEDES en curso de lenguaje señas de Guatemala" class="noticia-imagen-header" />
            <div class="div-block-7">
              <div class="titulo-noticia-item">Curso lengua de señas de Guatemala en ASEDES</div>
              <div class="informacion-noticia-item">Contamos con cursos en modalidad virtual y presencialcon diferentes opciones de niveles y horarios, todos nuestros instructores sonpersonas Sordas y nuestros cursos están pensados para llevar a una persona sinconocimiento previo de la lengua de señas hasta un nivel de dominio avanzado ..</div>
              <div class="link-leer-mas">LEER MÁS</div><img src="images/ic_go.svg" loading="lazy" alt="" class="image-11" />
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div id="aprender" class="whereto_row">
    <div class="container-2 w-container">
      <h2 id="donde_anchor" class="section_titles">¿Dónde puedo aprender LENSEGUA?</h2>
      <div class="text-block-2 custom">Dale click en una de estas instituciones para recibir información acerca de sus clases presenciales o virtuales de LENSEGUA. Síguelos en sus redes sociales para obtener más información.</div>
      <div class="columns w-row">
        <div class="column w-col w-col-3 w-col-small-6">
          <a href="https://www.facebook.com/asedesguatemala" target="_blank" class="where_cards asedes-link w-inline-block w-clearfix">
            <div class="where_cards_top where_top_asedes"></div>
            <div class="where_to_names">ASEDES</div><img src="images/ic_go.svg" alt="" class="ic_go"/>
          </a>
        </div>
        <div class="w-col w-col-3 w-col-small-6">
          <a href="https://www.facebook.com/ASORGUA/" target="_blank" class="where_cards asorgua-link w-inline-block w-clearfix">
            <div class="where_cards_top where_top_asorgua"></div>
            <div class="where_to_names">ASORGUA</div><img src="images/ic_go.svg" alt="" class="ic_go"/>
          </a>
        </div>
        <div class="w-col w-col-3 w-col-small-6">
          <a href="https://www.facebook.com/ProCiegosySordos/" target="_blank" class="where_cards prociegos-link w-inline-block w-clearfix">
            <div class="where_cards_top where_top_prociegos"></div>
            <div class="where_to_names">Benemérito Comité Prociegos y Sordos</div><img src="images/ic_go.svg" alt="" class="ic_go"/>
          </a>
        </div>
        <div class="w-col w-col-3 w-col-small-6">
          <a href="https://www.facebook.com/ensenasguatemala" target="_blank" class="where_cards ensenas-link w-inline-block w-clearfix">
            <div class="where_cards_top where_top_ensenas"></div>
            <div class="where_to_names">En-Señas Guatemala</div><img src="images/ic_go.svg" alt="" class="ic_go"/>
          </a>
        </div>
      </div>
      <a href="https://play.google.com/store/apps/details?id=com.kitsord&amp;hl=es_419" target="_blank" class="kitsord_banner kitsord_mobile_banner w-inline-block"><img src="images/kitsord_banner_mobile2x.jpg" sizes="100vw" srcset="images/kitsord_banner_mobile2x-p-500.jpeg 500w, images/kitsord_banner_mobile2x-p-800.jpeg 800w, images/kitsord_banner_mobile2x.jpg 928w" alt="" class="image-7"/></a>
    </div>
  </div>
  <div class="informativos_row">
    <div class="container-3 w-container">
      <h2 class="section_titles">Sitios informativos en LENSEGUA<br/></h2>
      <div class="text-block-2 custom">Dale click en una de estas instituciones conocer más acerca de la comunidad y poder aprender más de LENSEGUA. Síguelos en sus redes sociales y entérate de los eventos por venir acerca de LENSEGUA.</div>
      <div class="w-row">
        <div class="w-col w-col-6 w-col-small-6">
          <div class="info_cards"><img src="images/logo_cesgua2x.jpg" alt="" class="image-4"/>
            <div class="info_names">CESGUA</div>
            <div class="text-block-3">Somos un grupo enfocado en compartir conocimientos sobre temas de interés social a personas sordas por medio de conferencias impartidas por especialistas.<a href="https://www.facebook.com/CONFERENCIASCESGUA/" target="_blank"><br/></a>
            </div>
            <a href="https://www.facebook.com/CONFERENCIASCESGUA/" target="_blank" class="button cesgua-link w-button">CONOCE MÁS</a>
          </div>
        </div>
        <div class="w-col w-col-6 w-col-small-6">
          <div class="info_cards"><img src="images/logo_intergua2x.jpg" alt="" class="image-4"/>
            <div class="info_names">INTERGUA</div>
            <div class="text-block-3">Coordinación de intérpretes de lengua de señas de Guatemala.<a href="https://www.facebook.com/CONFERENCIASCESGUA/" target="_blank"><br/></a>
            </div>
            <a href="https://www.facebook.com/Intergua-105378164442763/" target="_blank" class="button intergua-link w-button">CONOCE MÁS</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <a href="https://kitsord.com/" target="_blank" class="w-inline-block w-clearfix">
      <div class="powered_txt">Powered by</div><img src="images/kitsord_logo_imago.svg" alt="" class="image-5" />
    </a>
  </div>
    </React.Fragment>
  );
}

export default Root;
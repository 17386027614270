import React, { useEffect, useState } from "react";
import { collection, query, getDocs } from "firebase/firestore";
import db from "../firebase";
import ReactPlayer from "react-player";
import { ReactComponent as Logo} from '../images/dale-play-sena.svg';
import AdsenseExample from "../components/AdsenseE";
import { Helmet } from "react-helmet";


function RetoLensegua() {
  const [reto, setReto] = useState({});
  const [contesto, setContesto] = useState(false);
  const [validacion, setValidacion] = useState(false);
  const [resEscrita, setResEscrita] = useState('');
  const [empezo, setEmpezo] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const q = query(collection(db, "evalpalabra"));
      const querySnapshot = await getDocs(q);
      const newData = querySnapshot.docs.map((doc) => ({...doc.data(), id:doc.id }));

      if(newData[0].tipo === 'jpg') setEmpezo(true);
      setReto(newData[0]);
    }
    fetchData();
  } ,[])

  const verificarRespuesta = () => {
    if(resEscrita !== '' && !contesto && empezo) {
      const answer = reto.respuestas.some(item => item === resEscrita.toLocaleLowerCase());
      setContesto(true);
      setValidacion(answer);
    }
  }

  const verificarOpciones = (res) => {
    if (!contesto && empezo) {
      setContesto(true);
      setValidacion(res);
    }
  }

  return (
    <React.Fragment>
    <Helmet>
        <title>Reta tu conocimiento en lengua de señas | LENSEGUA</title>
        <meta name="description" content="Reta tu conocimiento en lengua de señas de Guatemala y reta alguien más para que aprenda acerca de LENSEGUA." />
    </Helmet>
    <div class="main-content">
    <div class="content custom-content">
      <div class="container-4 w-container"><img src="images/kitsord_logo_imago.svg" loading="lazy" alt="" class="hello-image" />
        <h1 class="heading bold center">Reto LENSEGUA</h1>
        <h2 class="blog-section-title center">Realiza el reto siguiente y verifica tus conocimientos<br/>de LENSEGUA</h2>
        <div class="reto-sena-container">
          <div class="reto-sena-div">
            { reto.tipo === 'jpg' && 
              <div style={{position: 'relative', height: '100%'}}>
                <img src={reto.ubicacion} loading="lazy" alt="" class="resultado-imagen" /> 
              </div>
            }
            { reto.tipo === 'webm' && 
              <div style={{position: 'relative', height: '100%'}}>
                <ReactPlayer
                    playing
                    width='100%'
                    height='100%'
                    light= {true}
                    loop = {true}
                    playIcon = {<Logo />}
                    onStart={ () => {
                      setEmpezo(true);
                    }}
                    url={[
                      {src: reto.ubicacion, type: 'video/webm'},
                    ]}
                  />
              </div>
            }
          </div>
        </div>
        <div class="search-signs-form w-form">
          <div class="div-block-2 custom-reto">
            <h3 class="blog-section-title center">¿Qué está diciendo el intérprete?</h3>
            <div class="div-block-3">
              {reto.evaluacion === 'palabra' && 
                <div id="w-node-_5807e2f0-3ca6-f393-5532-611b363f4eb3-9b4eb6c9" class="reto-por-palabra">
                  <div class="buscar-title">El intérprete puede estar haciendo 1 o más señas</div>
                    <input class="sign-textfield normal-field remove w-input" maxLength={"256"} value={resEscrita} onChange={e => setResEscrita(e.target.value)} name="respuesta-reto" data-name="respuesta-reto" placeholder="Escribe tu respuesta aquí" type="text" id="respuesta-reto" />
                    <button onClick={verificarRespuesta} id="buscar-por-palabra" href="#" class="send-button green w-node-_5807e2f0-3ca6-f393-5532-611b363f4eb7-9b4eb6c9 w-button">Verificar</button>
                </div>
              }
              {reto.evaluacion === 'opciones' &&
                <div id="w-node-_9947d7b7-5dfd-7a4f-5243-f292b2fc3d17-9b4eb6c9" class="reto-por-opciones">
                  <div class="buscar-title">Escoge una de las siguientes opciones</div>
                  <div class="w-layout-grid grid-4">
                    {reto.opciones.map((item, key) => {
                      return (
                        <button key={key} onClick={() => verificarOpciones(item.valida)} id="buscar-por-palabra" href="#" class="send-button green opciones w-node-_9947d7b7-5dfd-7a4f-5243-f292b2fc3d1b-9b4eb6c9 w-button">{item.nombre}</button>
                      )
                    })}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {/* <AdsenseExample /> */}
        {contesto && 
          <div class={`texto-reto-resultado ${!validacion?"incorrecto" : ""}`}>
            {validacion && 
              <div class="text-block-5">Correcto ✅<br />Espera para el próximo reto anunciado en nuestras redes sociales</div>
            }
            {!validacion && 
              <div class="text-block-5">Respuesta correcta: {reto.nombre} <br />Espera para el próximo reto anunciado en nuestras redes sociales</div>
            }
          </div>
        }
        {/* Componente para aprender más */}
        {(contesto && !validacion) && 
          <div class="practicar-senas">
            <div class="practicar-container">
              <div class="text-block-6">Señas que podrías practicar</div>
              <div class="w-layout-grid resultados-grid">

                {reto.aprender.map((item, key) => 
                  <div key={key+item.nombre} id="w-node-bcb28e56-55ec-0082-db64-17ba95ad20be-0263dc3a" class="resultado">
                    <div class="resultado-head-container">
                      { item.tipo === 'jpg' && 
                        <div style={{position: 'relative', height: '100%'}}>
                          <img src={item.ubicacion} loading="lazy" alt="" class="resultado-imagen" /> 
                        </div>
                      }
                      { item.tipo === 'webm' && 
                        <div style={{position: 'relative', height: '100%'}}>
                          <ReactPlayer
                              playing
                              width='100%'
                              height='100%'
                              light= {true}
                              loop = {true}
                              playIcon = {<Logo />}
                              url={[
                                {src: item.ubicacion, type: 'video/webm'},
                              ]}
                            />
                        </div>
                      }
                    </div>
                    <div class="resultado-texto-container">
                      <div class="resultado-texto">{item.nombre}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
  <div class="footer">
    <a href="https://kitsord.com/" target="_blank" class="w-inline-block w-clearfix">
      <div class="powered_txt">Powered by</div><img src="images/kitsord_logo_imago.svg" alt="" class="image-5" />
    </a>
  </div>
    </React.Fragment>
  )
}

export default RetoLensegua;
// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyAKRFp6GHPNMuVn8ClUZ_UudQBM68guoA0",

  authDomain: "lensegua-20316.firebaseapp.com",

  databaseURL: "https://lensegua-20316.firebaseio.com",

  projectId: "lensegua-20316",

  storageBucket: "lensegua-20316.appspot.com",

  messagingSenderId: "640733556540",

  appId: "1:640733556540:web:1e8c82775ea1015a4e7839",

  measurementId: "G-9REWDPT1RP",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
const db = getFirestore(app);

export default db;

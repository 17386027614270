import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import { Helmet } from "react-helmet";

function Formulario() {
  const form = useRef();
  const [email, setEmail] = useState("");
  const [word, setWord] = useState("");
  const sendEmail = (e) => {
    e.preventDefault();

    if (email != '' && word != ''){
      emailjs
      .sendForm('default_service', 'template_zlxuvxk', form.current, {
        publicKey: 'user_KEDndGkiNDPgMKcjeV23B',
      })
      .then(
        () => {
          document.querySelector('#email-form').setAttribute('style', 'display:none;');
          document.querySelector('.custom-form-alert.w-form-done').setAttribute('style', 'display:block;');
        },
        (error) => {
          document.querySelector('.custom-form-alert.error-state.w-form-fail').setAttribute('style', 'display:block;');
        },
      );
    }
  };

  return(
    <React.Fragment>
      <Helmet>
          <title>Aprende lengua de señas | LENSEGUA</title>
          <meta name="description" content="Aprende lengua de señas preguntado que señas te gustaría aprender para ampliar tu conocimiento de LENSEGUA." />
      </Helmet>
      <div class="main-content">
      <div class="content custom-content">
      <div class="container-4 w-container"><img src="images/hello2.gif" loading="lazy" alt="Aprende lengua de señas" class="hello-image" />
        <h1 class="heading bold center"><strong>¿Qué señas te interesan aprender?</strong></h1>
        <h2 class="blog-section-title center">Escribe las señas que quieras aprender</h2>
        <div class="blog-paragraphs mod-mbottom">Al momento de escribir las señas u oraciones, ve separando cada seña con una coma (,) para saber cuales son las señas que deseas aprender. <br/>Nosotros nos estaremos poniendo en contacto para dar seguimiento a las señas que deseas aprender. Por ejemplo, puedes escribir: <strong>hola, como estás, mucho gusto<br/></strong><br/></div>
        <div class="send-sign-form w-form">
          <form ref={form} onSubmit={sendEmail} name="wf-form-email-form" data-name="email-form" method="post" action="#" data-wf-page-id="60d543b9051d8757ceffcae5" data-wf-element-id="be18b313-fa42-4cf4-1cd6-9cc0ca51dd56">
            <label htmlFor={"from_email-2"} class="field-label">Correo electrónico</label>
            <input class="sign-textfield w-input" autofocus="true" maxLength={"256"} value={email} onChange={e => setEmail(e.target.value)} name="from_email" data-name="from_email" placeholder="Correo electrónico" type="email" id="from_email" />
            <label htmlFor={"message-2"} class="field-label">Señas</label>
            <input class="sign-textfield normal-field w-input" maxLength={"256"} value={word} onChange={e => setWord(e.target.value)} name="message" data-name="message" placeholder="Ejemplo: Pizza, Quiero aprender lengua de señas, Necesito ir al baño" type="text" id="message" />
            <input type="submit" data-wait="Please wait..." class="send-button w-button" value="Enviar" />
          </form>
          <div class="custom-form-alert w-form-done">
            <div>¡Gracias por tu interés por aprender más sobre la lengua de señas!</div>
          </div>
          <div class="custom-form-alert error-state w-form-fail">
            <div>Oops! Hubo un error en el formulario. Revisa y vuelvelo a intentar.</div>
          </div>
        </div>
      </div>
    </div>
      <div class="footer">
      <a href="https://kitsord.com/" target="_blank" class="w-inline-block w-clearfix">
        <div class="powered_txt">Powered by</div><img src="images/kitsord_logo_imago.svg" alt="" class="image-5"/>
      </a>
    </div>
    </div>
    </React.Fragment>
  );
}

export default Formulario;